<template>
  <div class="schedule">
    <h2 v-if="title" class="mb-5">{{title}}</h2>
    <div class="section__acceleration-content-steps">
      <div v-for="step in steps" :key="step.title" class="section__acceleration-content-step">
        <div class="section__acceleration-content-step-wrapper">
          <h2>{{ step.title }}</h2>
          <div class="item-content" v-html="step.text"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Schedule",
  props: {
    title: {
      type: String,
      default: null
    },
    steps: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.section__acceleration-content-steps {
  .section__acceleration-content-step {
    &:last-child {
      padding-bottom: 0;
    }
  }
}
</style>